/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-danger */
import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Lead from '../components/Common/Lead';
import Accordion from '../components/Common/Accordion';
import Services from '../components/Common/Services';

const Section = styled.section`

  ul {
    margin: 0 0 4rem;

    ul {
      margin: 1rem 0 2rem 2rem;
      padding: 0;
    }
  }

  &:last-of-type {
    border-bottom: 0;
  }

  .columns {
    align-items: start!important;
    display: grid;
  }

  .mini-gallery {
    figure {
      text-align: center;
    }
  }

  @media only screen and (min-width: 960px) {
    .columns {
      align-items: center;
      grid-gap: 50px;
      grid-template-columns: repeat(2, 1fr);

      &--top {
        align-items: flex-start;
      }
    }
  }
`;

const AccordionData = [
  {
    question: '<p>Czy banknot obiegowy może być więcej wart niż nominał?</p>',
    answer: '<p>Tak, ale musi wystąpić jeden z poniższych warunków:</p><ol><li>specyficzna seria, zazwyczaj dotyczy to serii zastępczych <span>YA</span>, <span>ZA</span></li><li>specyficzny układ numeru:<ul><li>numer jednorodny (typu solid) <span>XX1111111</span> - <span>XX9999999</span></li><li>niski numer, np. <span>XX0000001</span> - <span>XX0000099</span></li><li>kolejne cyfry występujące po sobie <span>XX0123456</span></li><li>numer typu radar, np. <span>XX1234321</span>, <span>XX1010101</span>, <span>XX7779777</span></li></ul></li><li>wczesne serie emisji z 1994 r. <span>AA</span>, <span>AB</span> - przy założeniu, że banknot jest w&nbsp;stanie idealnym, UNC, bez żadnych załamań</li></ol>',
  },
  {
    question: '<p>Posiadam 10 banknotów z kolejnymi numerami, czy są więcej warte?</p>',
    answer: '<p>Nie, takie bankonty posiadają jedynie wartość nominalną.</p>',
  },
  {
    question: '<p>Mam banknot 200 zł z cyframi, których rozmiar się zwiększa? Czy to jest normalne?</p>',
    answer: '<p>W przypadku emisji z 1994 r. tak standardowo wygląda numer seryjny.</p>',
  },
  {
    question: '<p>Co to znaczy, że banknot jest w stanie UNC?</p>',
    answer: '<p>UNC z ang. "Uncirculated" - czyli nieobiegowy.<br />Banknot w stanie idealnym/emisyjnym, który nie ma żadnych śladów obiegu.</p>',
  },
];

const siteMetadata = {
  title: 'Skup i wycena starych banknotów - Monety Olsztyn',
  description: 'W naszym sklepie numizmatycznym chętnie skupujemy stare polskie banknoty obiegowe, banknoty kolekcjonerskie, wzory banknotów, banknoty w gradingu PMG.',
  siteName: 'Monety Olsztyn',
  imageUrl: 'https://monety.olsztyn.pl/favicons/splash-screen.png',
  siteUrl: 'https://monety.olsztyn.pl/skup-starych-banknotow/',
  locale: 'pl_PL',
};

const ValuationPage = ({ data }) => (
  <>
    <Helmet htmlAttributes={{ lang: 'pl' }}>
      <title>{siteMetadata.title}</title>
      <meta name="description" content={siteMetadata.description} />
      <meta property="og:locale" content={siteMetadata.locale} />
      <meta property="og:site_name" content={siteMetadata.siteName} />
      <meta property="og:image" content={siteMetadata.imageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteMetadata.siteUrl} />
      <meta property="og:title" content={siteMetadata.title} />
      <meta property="og:description" content={siteMetadata.description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={siteMetadata.title} />
      <meta name="twitter:description" content={siteMetadata.description} />
      <meta name="twitter:image" content={siteMetadata.imageUrl} />

      <link rel="canonical" href={siteMetadata.siteUrl} />
    </Helmet>

    <Section className="section-text">
      <h1>Skup starych banknotów</h1>

      <Lead
        maxWidth="700px"
        desc="Polskie banknoty mają bogatą i fascynującą historię, sięgającą XVIII wieku. Wprowadzenie banknotów było istotnym krokiem w rozwoju systemu monetarnego Polski, umożliwiając bardziej efektywne zarządzanie gospodarką i ułatwiając codzienne transakcje."
      />

      <h2 style={{ paddingTop: '3rem' }}>Historia polskich banknotów</h2>

      <p>Pierwsze polskie banknoty pojawiły się w czasach Insurekcji Kościuszkowskiej w 1794 roku. Były to bilety skarbowe, emitowane przez Skarb Państwa w celu sfinansowania powstania. Ich wartość była jednak krótko trwała ze względu na porażkę powstania i trudności gospodarcze.</p>

      <div className="columns mini-gallery">
        <figure className="col--6">
          <GatsbyImage
            image={getImage(data.insurekcja_500)}
            alt="10 złotych 1941 rok - awers"
          />
          <figcaption>We wrześniu 2020 r. podczas VI Aukcji Mateusza Wójcickiego, banknot o nominale 500 złotych został sprzedany za kwotę 350.000 zł</figcaption>
        </figure>

        <figure className="col--6">
          <GatsbyImage
            image={getImage(data.insurekcja_1000)}
            alt="10 złotych 1941 rok - awers"
          />
          <figcaption>1000 złotych 1794 - najwyższy nominał banknotu Insurekcji Kościuszkowskiej</figcaption>
        </figure>
      </div>

      <p>Po rozbiorach Polski, ziemie polskie znajdowały się pod kontrolą trzech zaborców: Rosji, Prus i Austrii, co skutkowało wprowadzeniem różnych walut obcych. Dopiero po odzyskaniu niepodległości w 1918 roku, Polska mogła ponownie wprowadzić własną walutę. W 1924 roku, w ramach reformy Władysława Grabskiego, wprowadzono polskiego złotego (PLN), który zastąpił markę polską. W tym czasie wyemitowano nowe banknoty, które szybko stały się symbolem odradzającej się państwowości.</p>

      <figure style={{ textAlign: 'center' }}>
        <GatsbyImage
          image={getImage(data.polskie_marki)}
          alt="10 złotych 1941 rok - awers"
        />
        <figcaption>1000 marek 1916 r. - banknot Generalnego Gubernatorstwa Warszawskiego, drukowany na papierze prążkowanym</figcaption>
      </figure>

      <p>W okresie międzywojennym banknoty polskie charakteryzowały się wysoką jakością druku i zaawansowanymi zabezpieczeniami przed fałszerstwami. Po II wojnie światowej, w 1950 roku, przeprowadzono denominację, która miała na celu ustabilizowanie gospodarki. Wprowadzono nowe banknoty, które na przestrzeni lat zmieniały swoje wzory, aby dostosować się do zmieniających się realiów politycznych i ekonomicznych.</p>

      <figure style={{ textAlign: 'center' }}>
        <GatsbyImage
          image={getImage(data.project)}
          alt="10 złotych 1941 rok - awers"
        />
        <figcaption>10 złotych 1941 - awers druku próbnego</figcaption>
      </figure>

      <h3>Od Biletów Skarbowych do&nbsp;nowoczesnych banknotów</h3>

      <p>W latach 90. XX wieku, po transformacji ustrojowej, Polska ponownie zdenominowała złotego. W 1995 roku wprowadzono nowe banknoty, które obowiązują do dziś, choć od tego czasu wprowadzono wiele modernizacji i zabezpieczeń, aby przeciwdziałać fałszerstwom. Najnowsze banknoty, wydane przez Narodowy Bank Polski, charakteryzują się zaawansowanymi technologiami zabezpieczającymi, takimi jak znaki wodne, nitki zabezpieczające i elementy holograficzne.</p>

      <p>Współczesne polskie banknoty przedstawiają ważne postacie z historii Polski, takie jak Mieszko I, Bolesław Chrobry, czy Kazimierz Wielki. Każdy banknot jest małym dziełem sztuki, które nie tylko pełni funkcję środka płatniczego, ale również przypomina o bogatej historii i kulturze Polski.</p>

      <p>Polskie pieniądze papierowe przeszły długą drogę od prostych biletów skarbowych do nowoczesnych banknotów, które są symbolem stabilności i niezależności ekonomicznej kraju. Są one nie tylko narzędziem codziennych transakcji, ale również świadectwem historii i tożsamości narodu polskiego.</p>

      <h2>Wycena banknotów</h2>

      <p>Proces <Link to="/wycena-monet-i-banknotow/">wyceny banknotów</Link> polega na przesłaniu do nas zdjęć przedmiotu oraz podaniu do siebie kontaktu, po oszacowaniu jego wstępnej wartości skontaktujemy się z Państwem z propozycją ceny. Wycena starych banknotów on-line jest wygodna i oszczędza czas naszych klientów. Oferujemy również wysyłkowy skup starych banknotów.</p>

      <p>Głównie interesują nas:</p>

      <ul>
        <li><strong>polskie banknoty obiegowe</strong> od Biletów Skarbowych Insurekcji Kościuszkowskiej do obecnej emisji</li>
        <li>wzory polskich banknotów</li>
        <li>projekty banknotów związanych z Polską</li>
        <li><strong>polskie marki - emisja 1916 r. (jenerał/Generał)</strong></li>
        <li>banknoty Wolnego Miasta Gdańska</li>
        <li>Bilety Kasowe Księstwa Warszawskiego</li>
        <li>banknoty niemieckich władz okupacyjnych</li>
        <li>banknoty Getta w Łodzi</li>
        <li>banknoty w gradingu PMG, PCGS, GDA</li>
        <li>banknoty kolekcjonerskie emitowane od 2006 r.</li>
        <li>polskie banknoty testowe i próbne</li>
        <li>wzory banknotów PWPW</li>
        <li>paczki i zgrzewki bankowe</li>
      </ul>

      <p>Chętnie skupujemy również:</p>

      <ul>
        <li>bony towarowe Pewex’u i Baltona</li>
        <li>polskie akcje i obligacje polskie</li>
        <li>banknoty zagraniczne</li>
      </ul>
    </Section>

    <Section className="section-text section-text--no-border">
      <h2>Faq</h2>

      <Accordion data={AccordionData} />
    </Section>

    <Services />
  </>
);

export const query = graphql`
  query {
    insurekcja_500: file(relativePath: {regex: "/500-zlotych-1794/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 300
        )
      }
    }
    insurekcja_1000: file(relativePath: {regex: "/1000-zlotych-1794/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 300
        )
      }
    }
    polskie_marki: file(relativePath: {regex: "/1000-marek-1916/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 900
        )
      }
    }
    project: file(relativePath: {regex: "/10-zlotych-1941-projekt/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 640
        )
      }
    }
  }
`;

export default ValuationPage;
